// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-blog-2020-august-comparing-life-insurance-quote-could-save-your-thousands-of-dollars-mdx": () => import("./../../../src/pages/blog/2020/august/comparing-life-insurance-quote-could-save-your-thousands-of-dollars.mdx" /* webpackChunkName: "component---src-pages-blog-2020-august-comparing-life-insurance-quote-could-save-your-thousands-of-dollars-mdx" */),
  "component---src-pages-blog-2020-august-how-expensive-was-lockdown-mdx": () => import("./../../../src/pages/blog/2020/august/how-expensive-was-lockdown.mdx" /* webpackChunkName: "component---src-pages-blog-2020-august-how-expensive-was-lockdown-mdx" */),
  "component---src-pages-blog-2020-august-the-top-5-ways-to-save-money-on-electricity-in-2020-mdx": () => import("./../../../src/pages/blog/2020/august/the-top-5-ways-to-save-money-on-electricity-in-2020.mdx" /* webpackChunkName: "component---src-pages-blog-2020-august-the-top-5-ways-to-save-money-on-electricity-in-2020-mdx" */),
  "component---src-pages-blog-2020-august-what-is-family-life-insurance-6-things-to-know-mdx": () => import("./../../../src/pages/blog/2020/august/what-is-family-life-insurance-6-things-to-know.mdx" /* webpackChunkName: "component---src-pages-blog-2020-august-what-is-family-life-insurance-6-things-to-know-mdx" */),
  "component---src-pages-blog-2020-july-five-questions-to-ask-when-you-compare-insurance-policies-and-prices-mdx": () => import("./../../../src/pages/blog/2020/july/five-questions-to-ask-when-you-compare-insurance-policies-and-prices.mdx" /* webpackChunkName: "component---src-pages-blog-2020-july-five-questions-to-ask-when-you-compare-insurance-policies-and-prices-mdx" */),
  "component---src-pages-blog-2020-july-four-keys-for-finding-todays-cheapest-internet-offers-mdx": () => import("./../../../src/pages/blog/2020/july/four-keys-for-finding-todays-cheapest-internet-offers.mdx" /* webpackChunkName: "component---src-pages-blog-2020-july-four-keys-for-finding-todays-cheapest-internet-offers-mdx" */),
  "component---src-pages-blog-2020-july-six-benefits-you-get-when-you-compare-electricity-bills-mdx": () => import("./../../../src/pages/blog/2020/july/six-benefits-you-get-when-you-compare-electricity-bills.mdx" /* webpackChunkName: "component---src-pages-blog-2020-july-six-benefits-you-get-when-you-compare-electricity-bills-mdx" */),
  "component---src-pages-blog-2020-july-who-has-the-cheapest-home-insurance-mdx": () => import("./../../../src/pages/blog/2020/july/who-has-the-cheapest-home-insurance.mdx" /* webpackChunkName: "component---src-pages-blog-2020-july-who-has-the-cheapest-home-insurance-mdx" */),
  "component---src-pages-blog-2020-june-get-a-lower-electrictity-bill-by-comparing-prices-mdx": () => import("./../../../src/pages/blog/2020/june/get-a-lower-electrictity-bill-by-comparing-prices.mdx" /* webpackChunkName: "component---src-pages-blog-2020-june-get-a-lower-electrictity-bill-by-comparing-prices-mdx" */),
  "component---src-pages-blog-2020-june-seven-genius-ways-to-save-money-on-cable-mdx": () => import("./../../../src/pages/blog/2020/june/seven-genius-ways-to-save-money-on-cable.mdx" /* webpackChunkName: "component---src-pages-blog-2020-june-seven-genius-ways-to-save-money-on-cable-mdx" */),
  "component---src-pages-blog-2020-june-six-easy-ways-to-save-money-on-insurance-in-2020-mdx": () => import("./../../../src/pages/blog/2020/june/six-easy-ways-to-save-money-on-insurance-in-2020.mdx" /* webpackChunkName: "component---src-pages-blog-2020-june-six-easy-ways-to-save-money-on-insurance-in-2020-mdx" */),
  "component---src-pages-blog-2020-june-six-key-things-to-know-about-life-insurance-rates-mdx": () => import("./../../../src/pages/blog/2020/june/six-key-things-to-know-about-life-insurance-rates.mdx" /* webpackChunkName: "component---src-pages-blog-2020-june-six-key-things-to-know-about-life-insurance-rates-mdx" */),
  "component---src-pages-blog-2020-june-where-are-the-best-student-loan-rates-mdx": () => import("./../../../src/pages/blog/2020/june/where-are-the-best-student-loan-rates.mdx" /* webpackChunkName: "component---src-pages-blog-2020-june-where-are-the-best-student-loan-rates-mdx" */),
  "component---src-pages-blog-2020-may-seven-rules-to-save-money-on-bills-mdx": () => import("./../../../src/pages/blog/2020/may/seven-rules-to-save-money-on-bills.mdx" /* webpackChunkName: "component---src-pages-blog-2020-may-seven-rules-to-save-money-on-bills-mdx" */),
  "component---src-pages-blog-2020-november-the-8-best-renters-insurance-providers-of-2020-mdx": () => import("./../../../src/pages/blog/2020/november/the-8-best-renters-insurance-providers-of-2020.mdx" /* webpackChunkName: "component---src-pages-blog-2020-november-the-8-best-renters-insurance-providers-of-2020-mdx" */),
  "component---src-pages-blog-2020-october-5-best-free-email-marketing-services-mdx": () => import("./../../../src/pages/blog/2020/october/5-best-free-email-marketing-services.mdx" /* webpackChunkName: "component---src-pages-blog-2020-october-5-best-free-email-marketing-services-mdx" */),
  "component---src-pages-blog-2020-october-5-best-password-managers-for-business-mdx": () => import("./../../../src/pages/blog/2020/october/5-best-password-managers-for-business.mdx" /* webpackChunkName: "component---src-pages-blog-2020-october-5-best-password-managers-for-business-mdx" */),
  "component---src-pages-blog-2020-october-how-can-you-choose-the-best-email-marketing-services-7-essential-things-to-look-for-mdx": () => import("./../../../src/pages/blog/2020/october/how-can-you-choose-the-best-email-marketing-services-7-essential-things-to-look-for.mdx" /* webpackChunkName: "component---src-pages-blog-2020-october-how-can-you-choose-the-best-email-marketing-services-7-essential-things-to-look-for-mdx" */),
  "component---src-pages-blog-2020-september-compare-insurance-policies-without-getting-overwhelmed-mdx": () => import("./../../../src/pages/blog/2020/september/compare-insurance-policies-without-getting-overwhelmed.mdx" /* webpackChunkName: "component---src-pages-blog-2020-september-compare-insurance-policies-without-getting-overwhelmed-mdx" */),
  "component---src-pages-blog-2020-september-eleven-factors-that-affect-your-home-insurance-rate-mdx": () => import("./../../../src/pages/blog/2020/september/eleven-factors-that-affect-your-home-insurance-rate.mdx" /* webpackChunkName: "component---src-pages-blog-2020-september-eleven-factors-that-affect-your-home-insurance-rate-mdx" */),
  "component---src-pages-blog-2020-september-finding-the-right-car-insurance-for-you-mdx": () => import("./../../../src/pages/blog/2020/september/finding-the-right-car-insurance-for-you.mdx" /* webpackChunkName: "component---src-pages-blog-2020-september-finding-the-right-car-insurance-for-you-mdx" */),
  "component---src-pages-blog-2020-september-when-should-you-consider-a-family-life-insurance-plan-mdx": () => import("./../../../src/pages/blog/2020/september/when-should-you-consider-a-family-life-insurance-plan.mdx" /* webpackChunkName: "component---src-pages-blog-2020-september-when-should-you-consider-a-family-life-insurance-plan-mdx" */),
  "component---src-pages-blog-2020-september-where-can-you-find-cheap-tv-rates-in-2020-mdx": () => import("./../../../src/pages/blog/2020/september/where-can-you-find-cheap-tv-rates-in-2020.mdx" /* webpackChunkName: "component---src-pages-blog-2020-september-where-can-you-find-cheap-tv-rates-in-2020-mdx" */),
  "component---src-pages-blog-2021-february-finding-the-best-motorcycle-insurance-mdx": () => import("./../../../src/pages/blog/2021/february/finding-the-best-motorcycle-insurance.mdx" /* webpackChunkName: "component---src-pages-blog-2021-february-finding-the-best-motorcycle-insurance-mdx" */),
  "component---src-pages-blog-2021-february-getting-the-right-insurance-as-a-renter-mdx": () => import("./../../../src/pages/blog/2021/february/getting-the-right-insurance-as-a-renter.mdx" /* webpackChunkName: "component---src-pages-blog-2021-february-getting-the-right-insurance-as-a-renter-mdx" */),
  "component---src-pages-blog-2021-february-keeping-safe-online-with-a-password-manager-mdx": () => import("./../../../src/pages/blog/2021/february/keeping-safe-online-with-a-password-manager.mdx" /* webpackChunkName: "component---src-pages-blog-2021-february-keeping-safe-online-with-a-password-manager-mdx" */),
  "component---src-pages-blog-2021-march-an-introduction-to-cryptocurrencies-mdx": () => import("./../../../src/pages/blog/2021/march/an-introduction-to-cryptocurrencies.mdx" /* webpackChunkName: "component---src-pages-blog-2021-march-an-introduction-to-cryptocurrencies-mdx" */),
  "component---src-pages-blog-2021-march-what-to-consider-when-hiring-a-cleaning-service-mdx": () => import("./../../../src/pages/blog/2021/march/what-to-consider-when-hiring-a-cleaning-service.mdx" /* webpackChunkName: "component---src-pages-blog-2021-march-what-to-consider-when-hiring-a-cleaning-service-mdx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-business-appointment-scheduling-services-mdx": () => import("./../../../src/pages/business/appointment-scheduling-services.mdx" /* webpackChunkName: "component---src-pages-business-appointment-scheduling-services-mdx" */),
  "component---src-pages-business-best-credit-services-mdx": () => import("./../../../src/pages/business/best-credit-services.mdx" /* webpackChunkName: "component---src-pages-business-best-credit-services-mdx" */),
  "component---src-pages-business-best-email-marketing-services-mdx": () => import("./../../../src/pages/business/best-email-marketing-services.mdx" /* webpackChunkName: "component---src-pages-business-best-email-marketing-services-mdx" */),
  "component---src-pages-business-best-payroll-companies-mdx": () => import("./../../../src/pages/business/best-payroll-companies.mdx" /* webpackChunkName: "component---src-pages-business-best-payroll-companies-mdx" */),
  "component---src-pages-business-best-web-hosting-services-mdx": () => import("./../../../src/pages/business/best-web-hosting-services.mdx" /* webpackChunkName: "component---src-pages-business-best-web-hosting-services-mdx" */),
  "component---src-pages-business-best-website-builder-services-mdx": () => import("./../../../src/pages/business/best-website-builder-services.mdx" /* webpackChunkName: "component---src-pages-business-best-website-builder-services-mdx" */),
  "component---src-pages-business-domains-mdx": () => import("./../../../src/pages/business/domains.mdx" /* webpackChunkName: "component---src-pages-business-domains-mdx" */),
  "component---src-pages-business-small-business-bank-mdx": () => import("./../../../src/pages/business/small-business-bank.mdx" /* webpackChunkName: "component---src-pages-business-small-business-bank-mdx" */),
  "component---src-pages-business-small-business-credit-cards-mdx": () => import("./../../../src/pages/business/small-business-credit-cards.mdx" /* webpackChunkName: "component---src-pages-business-small-business-credit-cards-mdx" */),
  "component---src-pages-business-small-business-insurance-mdx": () => import("./../../../src/pages/business/small-business-insurance.mdx" /* webpackChunkName: "component---src-pages-business-small-business-insurance-mdx" */),
  "component---src-pages-business-small-business-loans-mdx": () => import("./../../../src/pages/business/small-business-loans.mdx" /* webpackChunkName: "component---src-pages-business-small-business-loans-mdx" */),
  "component---src-pages-business-the-best-business-voip-providers-mdx": () => import("./../../../src/pages/business/the-best-business-voip-providers.mdx" /* webpackChunkName: "component---src-pages-business-the-best-business-voip-providers-mdx" */),
  "component---src-pages-business-the-best-password-managers-mdx": () => import("./../../../src/pages/business/the-best-password-managers.mdx" /* webpackChunkName: "component---src-pages-business-the-best-password-managers-mdx" */),
  "component---src-pages-cell-best-cell-phone-plans-mdx": () => import("./../../../src/pages/cell/best-cell-phone-plans.mdx" /* webpackChunkName: "component---src-pages-cell-best-cell-phone-plans-mdx" */),
  "component---src-pages-cell-the-best-phones-mdx": () => import("./../../../src/pages/cell/the-best-phones.mdx" /* webpackChunkName: "component---src-pages-cell-the-best-phones-mdx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-financial-best-budgeting-software-mdx": () => import("./../../../src/pages/financial/best-budgeting-software.mdx" /* webpackChunkName: "component---src-pages-financial-best-budgeting-software-mdx" */),
  "component---src-pages-financial-bitcoin-services-mdx": () => import("./../../../src/pages/financial/bitcoin-services.mdx" /* webpackChunkName: "component---src-pages-financial-bitcoin-services-mdx" */),
  "component---src-pages-financial-comparing-identity-protection-service-mdx": () => import("./../../../src/pages/financial/comparing-identity-protection-service.mdx" /* webpackChunkName: "component---src-pages-financial-comparing-identity-protection-service-mdx" */),
  "component---src-pages-financial-credit-monitoring-identity-theft-monitoring-mdx": () => import("./../../../src/pages/financial/credit-monitoring-identity-theft-monitoring.mdx" /* webpackChunkName: "component---src-pages-financial-credit-monitoring-identity-theft-monitoring-mdx" */),
  "component---src-pages-financial-debt-solutions-mdx": () => import("./../../../src/pages/financial/debt-solutions.mdx" /* webpackChunkName: "component---src-pages-financial-debt-solutions-mdx" */),
  "component---src-pages-financial-saving-accounts-mdx": () => import("./../../../src/pages/financial/saving-accounts.mdx" /* webpackChunkName: "component---src-pages-financial-saving-accounts-mdx" */),
  "component---src-pages-financial-tax-and-accounting-services-mdx": () => import("./../../../src/pages/financial/tax-and-accounting-services.mdx" /* webpackChunkName: "component---src-pages-financial-tax-and-accounting-services-mdx" */),
  "component---src-pages-financial-the-best-credit-cards-mdx": () => import("./../../../src/pages/financial/the-best-credit-cards.mdx" /* webpackChunkName: "component---src-pages-financial-the-best-credit-cards-mdx" */),
  "component---src-pages-home-best-home-security-systems-mdx": () => import("./../../../src/pages/home/best-home-security-systems.mdx" /* webpackChunkName: "component---src-pages-home-best-home-security-systems-mdx" */),
  "component---src-pages-home-best-internet-service-providers-mdx": () => import("./../../../src/pages/home/best-internet-service-providers.mdx" /* webpackChunkName: "component---src-pages-home-best-internet-service-providers-mdx" */),
  "component---src-pages-home-best-password-managers-mdx": () => import("./../../../src/pages/home/best-password-managers.mdx" /* webpackChunkName: "component---src-pages-home-best-password-managers-mdx" */),
  "component---src-pages-home-best-tv-service-providers-mdx": () => import("./../../../src/pages/home/best-tv-service-providers.mdx" /* webpackChunkName: "component---src-pages-home-best-tv-service-providers-mdx" */),
  "component---src-pages-home-home-cleaning-services-mdx": () => import("./../../../src/pages/home/home-cleaning-services.mdx" /* webpackChunkName: "component---src-pages-home-home-cleaning-services-mdx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-insurance-car-insurance-quotes-mdx": () => import("./../../../src/pages/insurance/car-insurance-quotes.mdx" /* webpackChunkName: "component---src-pages-insurance-car-insurance-quotes-mdx" */),
  "component---src-pages-insurance-compare-life-insurance-products-mdx": () => import("./../../../src/pages/insurance/compare-life-insurance-products.mdx" /* webpackChunkName: "component---src-pages-insurance-compare-life-insurance-products-mdx" */),
  "component---src-pages-insurance-health-insurance-plans-mdx": () => import("./../../../src/pages/insurance/health-insurance-plans.mdx" /* webpackChunkName: "component---src-pages-insurance-health-insurance-plans-mdx" */),
  "component---src-pages-insurance-homeowners-mdx": () => import("./../../../src/pages/insurance/homeowners.mdx" /* webpackChunkName: "component---src-pages-insurance-homeowners-mdx" */),
  "component---src-pages-insurance-medicare-mdx": () => import("./../../../src/pages/insurance/medicare.mdx" /* webpackChunkName: "component---src-pages-insurance-medicare-mdx" */),
  "component---src-pages-insurance-motorcycle-insurance-mdx": () => import("./../../../src/pages/insurance/motorcycle-insurance.mdx" /* webpackChunkName: "component---src-pages-insurance-motorcycle-insurance-mdx" */),
  "component---src-pages-insurance-renters-insurance-mdx": () => import("./../../../src/pages/insurance/renters-insurance.mdx" /* webpackChunkName: "component---src-pages-insurance-renters-insurance-mdx" */),
  "component---src-pages-loans-auto-loan-rates-mdx": () => import("./../../../src/pages/loans/auto-loan-rates.mdx" /* webpackChunkName: "component---src-pages-loans-auto-loan-rates-mdx" */),
  "component---src-pages-loans-compare-home-loans-mdx": () => import("./../../../src/pages/loans/compare-home-loans.mdx" /* webpackChunkName: "component---src-pages-loans-compare-home-loans-mdx" */),
  "component---src-pages-loans-personal-loan-rates-mdx": () => import("./../../../src/pages/loans/personal-loan-rates.mdx" /* webpackChunkName: "component---src-pages-loans-personal-loan-rates-mdx" */),
  "component---src-pages-loans-refinance-mortgage-rates-mdx": () => import("./../../../src/pages/loans/refinance-mortgage-rates.mdx" /* webpackChunkName: "component---src-pages-loans-refinance-mortgage-rates-mdx" */),
  "component---src-pages-loans-refinancing-student-loans-mdx": () => import("./../../../src/pages/loans/refinancing-student-loans.mdx" /* webpackChunkName: "component---src-pages-loans-refinancing-student-loans-mdx" */),
  "component---src-pages-partners-contact-us-jsx": () => import("./../../../src/pages/partners-contact-us.jsx" /* webpackChunkName: "component---src-pages-partners-contact-us-jsx" */),
  "component---src-pages-premium-jsx": () => import("./../../../src/pages/premium.jsx" /* webpackChunkName: "component---src-pages-premium-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-utilities-electricity-prices-by-state-mdx": () => import("./../../../src/pages/utilities/electricity-prices-by-state.mdx" /* webpackChunkName: "component---src-pages-utilities-electricity-prices-by-state-mdx" */),
  "component---src-pages-utilities-gas-rates-by-state-mdx": () => import("./../../../src/pages/utilities/gas-rates-by-state.mdx" /* webpackChunkName: "component---src-pages-utilities-gas-rates-by-state-mdx" */),
  "component---src-pages-utilities-texas-fixed-rate-electricity-plans-mdx": () => import("./../../../src/pages/utilities/texas-fixed-rate-electricity-plans.mdx" /* webpackChunkName: "component---src-pages-utilities-texas-fixed-rate-electricity-plans-mdx" */)
}

